<template>
    <Header page="detail" />
    <Detail />
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
import Header from '@/components/Layout/Header.vue' 
import Detail from '../prismaccess/src/components/detail/Main.vue';
import "../prismaccess/src/assets/css/icon.css";
import "../prismaccess/src/assets/css/fontawesome-5.11.2/css/all.min.css";
import "../prismaccess/src/assets/css/fontawesome-5.11.2/css/fontawesome.min.css"
import "@/assets/css/detail.css";
import "@/assets/css/detail_icon.css";
export default {
    components:{
        Header,
        Detail
    },
    setup(){        
        onMounted(() => {
            document.body.classList.add('prismheor-detail')
        });
        onUnmounted(() => {
            document.body.classList.remove('prismheor-detail')
        })
    },    
}
</script>
