<template>
    <header v-if="page === 'home'" class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <div class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
            <div class="dropdown toppage">
                <button class="btn btn-sea-blue btn-sm dropdown-toggle dropdown-ct" type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    {{$t("My Account")}}
                    <span><font-awesome-icon icon="chevron-down" :style="{ marginLeft: '5px' }" /> </span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">                    
                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-feedback">FEEDBACK</a></li>
                    <li><a class="dropdown-item red" href="#" data-bs-toggle="modal" data-bs-target="#modalConfirmLogout">SIGN OUT</a></li>
                </ul>
            </div>
            <div class="mode-web" @click="switch_mode()">
                <img v-if="mode == 'dark'" src="@/assets/images/icon/light-mode.svg" alt="" >
                <img v-else src="@/assets/images/icon/dark-mode.svg" alt="" >
            </div>
            
        </div>

        <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li><router-link to="/" >
            <img v-if="mode == 'dark'" src="@/assets/images/logo_dark.svg" style="height: 36px; margin-bottom: -5px;" alt="" >
            <img v-else src="@/assets/images/logo.svg" style="height: 36px; margin-bottom: -5px;" alt="" >            
            </router-link>
            </li>
        </ul>

        <div class="col-md-3 text-end col-end">
            <div>Hello <strong>{{ customer_name }}</strong> 
            <span class="cursor-pointer" id="dropdownlang" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,10"><img :src="require(`@/assets/images/icon/flag/${$i18n.locale}.svg`)" alt="" > </span>
            <ul class="dropdown-menu lang-dropdown" aria-labelledby="dropdownlang">                             
                <li v-for="lang in list_lang" :key="lang" @click="setlang(lang)" class="cursor-pointer">
                    <img :src="require(`@/assets/images/icon/flag/${lang}.svg`)" style="height: 25px; margin-top: 5px;" alt="" >
                </li>
            </ul>
            </div>
            
        </div>
    </header>
    <header v-else class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 mb-2 border-bottom" :class="page === 'detail' ? 'container-fluid p-2' : ''" id="header">
        
        <div class="col-md-3 logo_single">
            <router-link to="/" class="mb-md-0 text-dark text-decoration-none" >
                <img src="@/assets/images/logo_single.svg" alt="" >
            </router-link>
            <div class="mode-web" @click="switch_mode()">
                <img v-if="mode == 'dark'" src="@/assets/images/icon/light-mode.svg" alt="" >
                <img v-else src="@/assets/images/icon/dark-mode.svg" alt="" >
            </div>
        </div>
        <div class="col-md-3">
            <div>Hello <strong>{{ customer_name }}</strong></div>
        </div>

        <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li class="nav-item dropdown nav-ct toppage">
                <a class="nav-link dropdown-toggle dropdown-ct" href="#" id="dropdownMenuButton1" role="button"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    <strong class="upper-case"> {{$t("My Account")}}</strong>
                    <span><font-awesome-icon icon="chevron-down" :style="{ marginLeft: '5px' }" /> </span>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">                    
                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-feedback">FEEDBACK</a></li>
                    <li><a class="dropdown-item red" href="#" data-bs-toggle="modal" data-bs-target="#modalConfirmLogout">SIGN OUT</a></li>
                </ul>
            </li>
            <li class="col-end">
                <span class="cursor-pointer" id="dropdownlang" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,10"><img :src="require(`@/assets/images/icon/flag/${$i18n.locale}.svg`)" alt="" style="height: 27px; margin-top: 6px;"> </span>
                <ul class="dropdown-menu lang-dropdown" aria-labelledby="dropdownlang">                             
                    <li v-for="lang in list_lang" :key="lang" @click="setlang(lang)" class="cursor-pointer">
                        <img :src="require(`@/assets/images/icon/flag/${lang}.svg`)" style="height: 25px; margin-top: 5px;" alt="" >
                    </li>
                </ul>
            </li>
        </ul> 
        
    </header>

    <!-- Modal Déconnexion Logout-->
    <div class="modal fade" ref="confirmlogout" id="modalConfirmLogout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue">
                <div class="confirm-feedback">
                    <h2>{{ $t("Log out of my account.") }}</h2>
                    <span>{{ $t("You will disconnect. Are you sure?") }}</span>
                    <div class="box-area">
                        <div class="box-btn-menu">
                            <button class="btn btn-outline-secondary btn-form mb-1"  data-bs-dismiss="modal"> {{ $t("Cancel") }}</button>
                            <button class="btn btn-sea-blue btn-form mb-1" @click="logout()">  {{ $t("Confirm") }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal Feedback -->
    <div class="modal fade" id="modal-feedback" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width:640px;">
          <div class="modal-content main-overlay border-blue">
              <div class="confirm-feedback">
                <h2>{{$t('Need help ?')}}</h2>
                <p>{{$t('Send us a message on any information you may need')}}</p> 
                <div v-if="msg_feedback.msg" :class="'text-left alert alert-'+msg_feedback.type" role="alert">
                    {{ msg_feedback.msg }}
                </div>         
                <div class="box-btn-menu" style="margin: 0px">                      
                    <textarea id="problem_text" v-model="inputFeedback" ></textarea>   
                    <button data-description="ICON Cancel feedback" data-bs-dismiss="modal" aria-label="Close" class="btn btn-danger"> {{ $t("Cancel") }}</button>
                    <button data-description="ICON Send feedback" @click="sendFeedback()" class="btn bg-blue">Send</button> 
                </div>
              </div>     
              <div v-if="loadingSendFeedback" class="overlay">
                  <div class="loadding_circle"></div>
              </div> 
          </div>
      </div>
    </div>
    <Loading v-if="isLoad"/>    
</template>

<script>
import { computed, onMounted, reactive, ref, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import useModeApp  from "@/hooks/useModeApp.js"
import { Modal } from 'bootstrap'
import Loading from '@/components/elements/Loading.vue';
export default {
    components : {
        Loading
    },
    props: {
        page: String
    },
    setup(){
        const store = useStore();
        const router = useRouter(); 
        const { locale } = useI18n();        

        const languages = ref(["fr", "en", "de"]);
        const modal = ref(null);
        const isLoad = ref(false);

        onMounted(() => {
            modal.value = new Modal(document.getElementById('modalConfirmLogout'));
            if (localStorage.getItem("language") !== locale.value) {
                window.location.reload();
            }
        })

        const customer_name = computed(() => {
            const user = JSON.parse(localStorage.getItem('user'));
            return user.name + " " + user.lastname 
        });
        const list_lang = computed(() => languages.value.filter(item => item !== locale.value) );  
        
        
        const logout = () => {     
            isLoad.value = true       
            store.dispatch("auth/logout").then(
                () => {
                    isLoad.value = false;
                    modal.value.hide();
                    router.push("/login");
                }
            );
        }
        const setlang = (lang) => {
            locale.value = lang;
            localStorage.setItem("language", lang);
            window.location.reload();
        }

        //-------------------------- feedback ------------------------[start]
        const inputFeedback = ref("");
        const loadingSendFeedback = ref(false)
        const msg_feedback = reactive({
            msg : '',
            type : 'success'
        });
        const sendFeedback = () => {
            msg_feedback.msg = "" 
            if(inputFeedback.value === ""){
                alert('Please enter your message.');
                return false;
            }
            loadingSendFeedback.value = true       
            store.dispatch("users/send_feedback", { link : location.href, message : inputFeedback.value }).then(
                (res) => {
                    if(res.sent){
                        msg_feedback.msg = "The message has been sent successfully.";
                        msg_feedback.type = "success"
                    }else{
                        msg_feedback.msg = "Feedback could not be send. Please try again later or send us an e-mail at "+res.mail;
                        msg_feedback.type = "danger"
                    }
                    loadingSendFeedback.value = false;                    
                    inputFeedback.value = "";
                }
            );

        }
        //-------------------------- feedback ------------------------[end]

        //-------------------------- switch mode  ------------------------[start] 
        const { mode, switch_mode, addClassToBody } = useModeApp();       
        watch(mode, ()  => { 
            addClassToBody() 
        })
        onMounted(() => {
            addClassToBody()
        });
        onUnmounted(() => {
            document.body.classList.remove('light-mode', 'dark-mode')
        })
        //-------------------------- switch mode  ------------------------[end]

        return {customer_name, list_lang, setlang, logout, isLoad, inputFeedback, sendFeedback, msg_feedback, 
        loadingSendFeedback, mode, switch_mode}
    }
   
     
}
</script>

<style scoped>
.lang-dropdown{
    min-width: 3rem;
    padding: .3rem 0;
}

.confirm-feedback textarea {
    width: 100%;
    height: 228px;
    margin-bottom: 40px;
    padding: 10px;
    border: none;
    background-color: #efefef;
}
.box-btn-menu button{
line-height: 1;
}
.confirm-feedback .bg-blue{ background-color: #1099d6; color: #fff;}
</style>